import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"
import BlockContent from "@sanity/block-content-to-react"
import { v4 as uuidv4 } from "uuid"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Breadcrumbs from "../components/ui/Breadcrumbs"
import Form from "../components/contact/Form"
import Link from "../components/Link"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 0 2.5rem 6rem;
`

const Header = styled.div`
  padding: 3rem 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`

const HelpCenterLink = styled(Link)`
  position: absolute;
  left: 0;
  bottom: -1.5rem;

  ${MEDIA_MIN_MEDIUM} {
    bottom: auto;
  }
`

const Title = styled.h1`
  text-align: center;
  justify-self: center;
`

const Text = styled.div`
  margin: 0 auto;
`

const Address = styled.p`
  padding: 0 0 2rem;
  border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightGrey};
  margin-bottom: 2rem;
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    flex-wrap: nowrap;
  }
`

const FormWrapper = styled.div`
  width: 100%;
  order: 2;

  ${MEDIA_MIN_MEDIUM} {
    order: 1;
    margin-right: 5rem;
  }
`

const ContactDetails = styled.div`
  display: flex;
  padding-top: 4rem;
  margin: 0 auto;
  order: 1;

  a {
    color: ${(p) => p.theme.colors.primary};
  }

  ${MEDIA_MIN_MEDIUM} {
    padding-top: 2rem;
    order: 2;
  }
`

const CenteredText = styled.span`
  width: 100%;
  text-align: center;
  display: block;
`

const ContactPage = ({ data: { sanityContactPage }, pageContext }) => {
  const { t } = useTranslation("translations")
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const { pageTitle, enableContactForm, address, information } =
    sanityContactPage || {}
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  const serializers = {
    marks: {
      internalLink: ({ mark, children }) => {
        const { slug = {} } = mark.reference
        if (mark.reference._type === "returnPage")
          return <Link to="/returns-and-exchanges">{children}</Link>
        if (mark.reference._type === "faqPage")
          return <Link to="/faq">{children}</Link>
        return <Link to={`/${slug.current}`}>{children}</Link>
      },
      link: ({ mark, children }) => (
        <a href={mark.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
      centerText: ({ children }) => <CenteredText>{children}</CenteredText>
    }
  }

  return (
    <LayoutComponent
      meta={{ title: pageTitle.en }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs
        breadcrumbs={[
          {
            slug: "/contact/",
            name: pageTitle.en
          }
        ]}
      />
      <Container>
        <Header>
          <HelpCenterLink to="/faq/" underline color="black">
            <i className="fal fa-long-arrow-left"></i> {t("FAQ")}
          </HelpCenterLink>
          <Title>{pageTitle.en}</Title>
        </Header>
        <Wrapper>
          {enableContactForm && (
            <FormWrapper>
              <Form />
            </FormWrapper>
          )}
          <ContactDetails>
            <Text>
              {address && (
                <Address>
                  {address.en.text.map((text) => (
                    <BlockContent
                      key={uuidv4()}
                      blocks={text}
                      serializers={serializers}
                    />
                  ))}
                </Address>
              )}
              {information && (
                <>
                  {information.en.text.map((text) => (
                    <BlockContent
                      key={uuidv4()}
                      blocks={text}
                      serializers={serializers}
                    />
                  ))}
                </>
              )}
            </Text>
          </ContactDetails>
        </Wrapper>
      </Container>
    </LayoutComponent>
  )
}

export default ContactPage

export const query = graphql`
  query {
    sanityContactPage {
      pageTitle {
        en
      }
      enableContactForm
      address: _rawContactAddress(resolveReferences: { maxDepth: 10 })
      information: _rawContactInformation(resolveReferences: { maxDepth: 10 })
    }
  }
`
